export type DjangoUser = {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
};

export type UDMPerson = {
  partyId: string;
  id: string;
  firstName: string;
  lastName: string;
};

export type FWUserProfile = {
  id: string;
  person: UDMPerson;
  user: DjangoUser;
  can_access_registers: boolean;
  can_access_admin: boolean;
};

export type FWRegisterCriteriaCorrectiveAction = {
  id: string;
  name: string;
};

export type FWRegisterCriteriaChoice = {
  id: string;
  value: string;
  is_error: boolean;
  is_success: boolean;
};

export type FWRegisterCriteriaValue = {
  register_criteria: FWRegisterCriteria;
  id: string;
  value: string;
  is_error: boolean;
  is_success: boolean;
  comments?: string;
  correctiveAction?: FWRegisterCriteriaCorrectiveAction;
};

export type FWRegisterCriteria = {
  id: string;
  name: string;
  order: number;
  correctiveActions: FWRegisterCriteriaCorrectiveAction[];
  valueChoices: FWRegisterCriteriaChoice[];
};

export type FWRegisterRecordStatus = {
  id: string;
  name: string;
};

export enum FWRegisterRecordStatusEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}

export type FWRegisterRecordEntry = {
  id: string;
  person?: UDMPerson;
  element?: FWElement;
  product?: FWProduct;
  registerCriteria: FWRegisterCriteria;
  registerCriteriaValues: FWRegisterCriteriaValue[];
  registerBatchValues: FWReceptionBatchValue[];
  registerObservableValues: FWReceptionObservableValue[];
};

export type FWReceptionObservableValue = {
  reception_observable_attribute: FWReceptionIdentifier;
  date_value?: string;
  datetime_value?: string;
  text_value?: string;
  number_value?: number;
  boolean_value?: boolean;
};

export type FWReceptionPreambleValue = {
  reception_preamble_attribute: FWReceptionIdentifier;
  date_value?: string;
  datetime_value?: string;
  text_value?: string;
  number_value?: number;
  boolean_value?: boolean;
};

export type FWReceptionBatchValue = {
  reception_batch_attribute: FWReceptionIdentifier;
  date_value?: string;
  datetime_value?: string;
  text_value?: string;
  number_value?: number;
  boolean_value?: boolean;
};

export type FWRegisterRecord = {
  id: string;
  entries?: FWRegisterRecordEntry[];
  receptionPreambleAttributeValues?: FWReceptionPreambleValue[];
  registerFormat: FWRegisterFormat;
  status: FWRegisterRecordStatus;
  title: string;
  created_by: FWUserProfile;
  comments?: string;
  entriesCount?: number;
};

export type FWElement = {
  id: string;
  name: string;
};

export enum FWRegisterRecordTargetEnum {
  PEOPLE = "PEOPLE",
  ELEMENTS = "ELEMENTS",
  PRODUCT_RECEPTION = "PRODUCT_RECEPTION",
}

export type FWPOES = {
  id: string;
  code: string;
  name: string;
  version: number;
};

//! Must match backend choices.
export enum FWAttributeFieldTypeEnum {
  DATE = "DATE",
  DATETIME = "DATETIME",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
}

export type FWObservableProductAttribute = {
  id: string;
  name: string;
  field_type: FWAttributeFieldTypeEnum;
  value: unknown;
};

export type FWReceptionIdentifier = {
  id: string;
  name: string;
  field_type: FWAttributeFieldTypeEnum;
  isOptional?: boolean;
  sortOrder?: number;
};

export type FWRegisterFormat = {
  active: boolean;
  code: string;
  criteria: FWRegisterCriteria[];
  elements: FWElement[];
  id: string;
  name: string;
  poes: FWPOES;
  target: FWRegisterRecordTargetEnum;
  version: number;
  workers: UDMPerson[];
  observableProductAttributes: FWObservableProductAttribute[];
  receptionBatchIdentifiers: FWReceptionIdentifier[];
  receptionPreambleIdentifiers: FWReceptionIdentifier[];
};

export type FWProductBrand = {
  id: string;
  name: string;
};

export type FWCountry = {
  id: string;
  name: string;
};

export type FWProduct = {
  id: string;
  name: string;
  brand: FWProductBrand;
  barcode?: string;
  internal_code?: string;
  weight_kg?: number;
  volume_ml?: number;
  origin_country: FWCountry;
};
