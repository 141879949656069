/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DjangoUser,
  FWAttributeFieldTypeEnum,
  FWCountry,
  FWElement,
  FWObservableProductAttribute,
  FWPOES,
  FWProduct,
  FWProductBrand,
  FWReceptionBatchValue,
  FWReceptionIdentifier,
  FWReceptionObservableValue,
  FWReceptionPreambleValue,
  FWRegisterCriteria,
  FWRegisterCriteriaChoice,
  FWRegisterCriteriaCorrectiveAction,
  FWRegisterCriteriaValue,
  FWRegisterFormat,
  FWRegisterRecord,
  FWRegisterRecordEntry,
  FWRegisterRecordStatus,
  FWUserProfile,
  UDMPerson,
} from "./FWTypes";

const anyToType = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapper: (d: any) => T = (d: any) => {
    const obj: T = { ...d };
    return obj;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((a: any) => T) => {
  return mapper;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const anyToDjangoUser = anyToType<DjangoUser>((data: any) => {
  const djangoUser = {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    username: data.username,
    email: data.email,
  };
  return djangoUser;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const anyToUDMPerson = anyToType<UDMPerson>((data: any) => {
  const udmPerson = {
    partyId: data.party?.id || data.party,
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
  };
  return udmPerson;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const anyToFWUserProfile = anyToType<FWUserProfile>((data: any) => {
  const userProfile = {
    id: data.id,
    person: anyToUDMPerson(data.person.person),
    user: anyToDjangoUser(data.user),
    can_access_registers: data?.can_access_registers || false,
    can_access_admin: data?.can_access_admin || false,
  };
  return userProfile;
});

export const anyToFWRegisterCriteriaCorrectiveActions =
  anyToType<FWRegisterCriteriaCorrectiveAction>();

export const anyToFWRegisterCriteriaChoice =
  anyToType<FWRegisterCriteriaChoice>();

export const anyToFWRegisterCriteria = anyToType<FWRegisterCriteria>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => {
    const criteria = {
      id: data.id,
      name: data.name,
      order: data.order,
      correctiveActions: (data?.corrective_actions || []).map(
        anyToFWRegisterCriteriaCorrectiveActions
      ),
      valueChoices: (data?.value_choices || []).map(
        anyToFWRegisterCriteriaChoice
      ),
    };
    return criteria;
  }
);

export const anyToFWRegisterCriteriaValue = anyToType<FWRegisterCriteriaValue>(
  (data: any) => {
    const criteriaValue = {
      register_criteria: anyToFWRegisterCriteria(data.register_criteria),
      id: data?.criteria_choice_value?.id,
      value: data?.criteria_choice_value?.value,
      is_error: data?.criteria_choice_value?.is_error,
      is_success: data?.criteria_choice_value?.is_success,
      comments: data?.criteria_comments,
      correctiveAction:
        data?.criteria_corrective_action &&
        anyToFWRegisterCriteriaCorrectiveActions(
          data?.criteria_corrective_action
        ),
    };
    return criteriaValue;
  }
);

export const anyToFWElement = anyToType<FWElement>();

export const anyToFWPOES = anyToType<FWPOES>();

export const anyToFWObservableProductAttribute =
  anyToType<FWObservableProductAttribute>((data: any) => {
    const field_type = data.field_type;
    let value = null;
    switch (field_type) {
      case FWAttributeFieldTypeEnum.BOOLEAN:
        value = data.boolean_value;
        break;
      case FWAttributeFieldTypeEnum.DATE:
        value = data.date_value;
        break;
      case FWAttributeFieldTypeEnum.DATETIME:
        value = data.datetime_value;
        break;
      case FWAttributeFieldTypeEnum.TEXT:
        value = data.text;
        break;
      case FWAttributeFieldTypeEnum.NUMBER:
        value = data.number_value;
        break;
    }
    const observableProductAttribute: FWObservableProductAttribute = {
      id: data.id,
      name: data.name,
      field_type: field_type,
      value: value,
    };
    return observableProductAttribute;
  });

export const anyToFWReceptionIdentifier = anyToType<FWReceptionIdentifier>(
  (data: any) => {
    const observableProductAttribute: FWReceptionIdentifier = {
      id: data.id,
      name: data.name,
      field_type: data.field_type,
      isOptional: data?.is_optional || false,
      sortOrder: data?.sort_order,
    };
    return observableProductAttribute;
  }
);

export const anyToFWRegisterFormat = anyToType<FWRegisterFormat>(
  (data: any) => {
    const registerFormat: FWRegisterFormat = {
      active: data?.is_active || false,
      code: data.code,
      criteria: (data?.criteria || []).map(anyToFWRegisterCriteria),
      id: data.id,
      elements: (data?.elements || []).map(anyToFWElement),
      name: data.name,
      poes: anyToFWPOES(data.poes),
      target: data.target_type,
      version: data.version,
      workers: (data?.workers || []).map(
        (w: any) => w?.person && anyToUDMPerson(w.person)
      ),
      observableProductAttributes: (
        data?.observable_product_attributes || []
      ).map(anyToFWObservableProductAttribute),
      receptionBatchIdentifiers: (data?.reception_batch_identifiers || []).map(
        anyToFWReceptionIdentifier
      ),
      receptionPreambleIdentifiers: (
        data?.reception_preamble_identifiers || []
      ).map(anyToFWReceptionIdentifier),
    };
    return registerFormat;
  }
);

export const anyToFWRegisterRecordStatus = anyToType<FWRegisterRecordStatus>();

export const anyToFWRegisterBatchValue = anyToType<FWReceptionBatchValue>(
  (data: any) => {
    const reception_batch_value: FWReceptionBatchValue = {
      reception_batch_attribute: {
        id: data.reception_batch_attribute.id,
        name: data.reception_batch_attribute.name,
        field_type: data.reception_batch_attribute.field_type,
      },
      date_value: data?.date_value,
      datetime_value: data?.datetime_value,
      text_value: data?.text_value,
      number_value: data?.number_value,
      boolean_value: data?.boolean_value,
    };
    return reception_batch_value;
  }
);

export const anyToFWRegisterRecordEntry = anyToType<FWRegisterRecordEntry>(
  (data: any) => {
    const registerRecordEntry: FWRegisterRecordEntry = {
      id: data.id,
      person: data?.person && anyToUDMPerson(data.person),
      element: data?.element && anyToFWElement(data.element),
      product: data?.product && anyToFWProduct(data.product),
      registerCriteria: (data?.register_criteria || []).map(
        anyToFWRegisterCriteria
      ),
      registerCriteriaValues: (data?.entry_values || []).map(
        anyToFWRegisterCriteriaValue
      ),
      registerBatchValues: (data?.entry_batch_values || []).map(
        anyToFWRegisterBatchValue
      ),
      registerObservableValues: (data?.entry_observable_values || []).map(
        anyToFWObservableProductAttributeValue
      ),
    };
    return registerRecordEntry;
  }
);

export const anyToFWReceptionPreambleValue =
  anyToType<FWReceptionPreambleValue>((data: any) => {
    const reception_preamble_value: FWReceptionPreambleValue = {
      reception_preamble_attribute: {
        id: data.reception_preamble_attribute.id,
        name: data.reception_preamble_attribute.name,
        field_type: data.reception_preamble_attribute.field_type,
      },
      date_value: data?.date_value,
      datetime_value: data?.datetime_value,
      text_value: data?.text_value,
      number_value: data?.number_value,
      boolean_value: data?.boolean_value,
    };
    return reception_preamble_value;
  });

export const anyToFWObservableProductAttributeValue =
  anyToType<FWReceptionObservableValue>((data: any) => {
    const reception_observable_value: FWReceptionObservableValue = {
      reception_observable_attribute: {
        id: data.reception_observable_attribute.id,
        name: data.reception_observable_attribute.name,
        field_type: data.reception_observable_attribute.field_type,
      },
      date_value: data?.date_value,
      datetime_value: data?.datetime_value,
      text_value: data?.text_value,
      number_value: data?.number_value,
      boolean_value: data?.boolean_value,
    };
    return reception_observable_value;
  });

export const anyToFWRegisterRecord = anyToType<FWRegisterRecord>(
  (data: any) => {
    const registerRecord: FWRegisterRecord = {
      id: data.id,
      entries: data?.entries && data.entries.map(anyToFWRegisterRecordEntry),
      registerFormat: anyToFWRegisterFormat(data.register_format),
      receptionPreambleAttributeValues:
        data?.reception_preamble_values &&
        data.reception_preamble_values.map(anyToFWReceptionPreambleValue),
      status: anyToFWRegisterRecordStatus(data.status),
      title: data.title,
      created_by: data.created_by && anyToFWUserProfile(data.created_by),
      comments: data?.comments,
      entriesCount: data?.entries_count,
    };
    return registerRecord;
  }
);

export const anyToFWBrand = anyToType<FWProductBrand>();

export const anyToFWCountry = anyToType<FWCountry>();

export const anyToFWProduct = anyToType<FWProduct>((data: any) => {
  const product: FWProduct = {
    id: data.id,
    name: data.name,
    brand: anyToFWBrand(data.brand),
    barcode: data?.barcode,
    internal_code: data?.internal_code,
    weight_kg: data?.weight_kg,
    volume_ml: data?.volume_ml,
    origin_country: anyToFWCountry(data.origin_country),
  };
  return product;
});
